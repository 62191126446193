<template>
  <div id="index">
    <div class="nav">
      <img src="../../assets/logo.png" alt="" />
    </div>

    <!-- IP-BOX -->
    <div class="title_box">
      <div class="ip_logo">
        <img src="../../assets/images/ip_box_logo.png" alt="" />
      </div>
      <div class="two_lines">
        <p>全球首创IP-BOX元宇宙</p>
        <p>虚拟现实制片</p>
      </div>
      <div class="border_bottom">
        <img src="../../assets/images/tit_border.png" alt="" />
      </div>
    </div>
    <div class="ip_img">
      <img src="../../assets/images/ip_box.png" alt="" />
    </div>
    <div class="text_box">
      <p>100%中国程序员自主研发</p>
      <p>人类历史上<span>第一台</span>虚拟制片一体机</p>
      <p><span>傻瓜式操作</span>，普惠价格，<span>人人皆可进入元宇宙</span></p>
    </div>

    <!-- 蓝图 -->
    <div class="title_box">
      <div class="lt_logo">
        <img src="../../assets/images/lt_logo.png" alt="" />
      </div>
      <div class="one_lines">
        <p>乌托邦 元宇宙制片系统引擎</p>
      </div>
      <div class="border_bottom">
        <img src="../../assets/images/tit_border.png" alt="" />
      </div>
    </div>
    <div class="lt_img">
      <img src="../../assets/images/lt_img.png" alt="" />
    </div>
    <div class="text_box">
      <span>全球首款人工智能虚拟制片引擎</span>
      <p>普通小白30分钟即可轻松掌控所有操作</p>
      <p>1小时内，普通人也能开始元宇宙制片</p>
    </div>

    <!-- showings -->
<!--    <div class="title_box">-->
<!--      <div class="showings_logo">-->
<!--        <img src="../../assets/images/showings_logo.png" alt="" />-->
<!--      </div>-->
<!--      <div class="border_bottom">-->
<!--        <img src="../../assets/images/tit_border.png" alt="" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="showings_img">-->
<!--      <p>元宇宙创作者集合平台</p>-->
<!--      <img src="../../assets/images/showings_img.png" alt="" />-->
<!--    </div>-->
<!--    <div class="text_box">-->
<!--      <p>全球首个元宇宙环境，<span>3D特效、场景、音效制片脚</span></p>-->
<!--      <p><span>本、素材、代码</span>及物料一体化共享共创共生平台</p>-->
<!--    </div>-->
    <div class="showings_img2">
      <img src="../../assets/images/showings_img2.png" alt="" />
    </div>
    <div class="text_box">
      <p>通过场景共创平台大量学习沉淀历史数据的<span>画布、模型、光</span></p>
      <p><span>效、脚本、骨骼、蓝图、景深姿态</span>等进行深度</p>
      <p>学习，最终形成系统化模型可快速生成海量元宇宙素材场景</p>
    </div>
<!--    <div class="showings_img3">-->
<!--      <div class="title">-->
<!--        <div class="lable">SHOWINGS作者收益榜</div>-->
<!--        <div class="lable">昨日销冠</div>-->
<!--      </div>-->
<!--      <div class="image">-->
<!--        <img src="../../assets/images/showings_img3.png" alt="" />-->
<!--      </div>-->
<!--    </div>-->

    <!-- fans-box -->
<!--    <div class="title_box">-->
<!--      <div class="fans_box_logo">-->
<!--        <img src="../../assets/images/fans_box_logo.png" alt="" />-->
<!--      </div>-->
<!--      <div class="one_lines">-->
<!--        <p>Fans-box元宇宙主播工会</p>-->
<!--      </div>-->
<!--      <div class="border_bottom">-->
<!--        <img src="../../assets/images/tit_border.png" alt="" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="explain">-->
<!--      <div class="info">-->
<!--        <div class="infoIcon">-->
<!--          <img src="../../assets/images/fans_box1.png" alt="" />-->
<!--        </div>-->
<!--        <div class="infoTxt">-->
<!--          <div class="topic">全球首个元宇宙直播工会</div>-->
<!--          <ul class="strip">-->
<!--            <li>储备人才、签约主播</li>-->
<!--            <li>打造矩阵、塑造环境</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="info">-->
<!--        <div class="infoTxt">-->
<!--          <div class="topic">建立城市合伙人机制</div>-->
<!--          <ul class="strip">-->
<!--            <li>孵化元宇宙制片师</li>-->
<!--            <li>人人都是合伙人</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="infoIcon">-->
<!--          <img src="../../assets/images/fans_box2.png" alt="" />-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="info">-->
<!--        <div class="infoIcon">-->
<!--          <img src="../../assets/images/fans_box3.png" alt="" />-->
<!--        </div>-->
<!--        <div class="infoTxt">-->
<!--          <div class="topic">建立城市服务中心</div>-->
<!--          <ul class="strip">-->
<!--            <li>建立城市服务中心</li>-->
<!--            <li>与硬件厂商战略合作</li>-->
<!--            <li>共建品牌，流量互通</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="bottom">
      <p>标准化设计快速复制、裂变计划</p>
      <div class="tab">一站式服务城市合伙人</div>
      <div class="items">
        <div class="item">
          <img src="../../assets/images/item1.png" alt="" />
          <span>拟定合同</span>
        </div>
        <div class="item">
          <img src="../../assets/images/item2.png" alt="" />
          <span>谈判技巧</span>
        </div>
        <div class="item">
          <img src="../../assets/images/item3.png" alt="" />
          <span>消费场景</span>
        </div>
        <div class="item">
          <img src="../../assets/images/item4.png" alt="" />
          <span>聊天话术</span>
        </div>
        <div class="item">
          <img src="../../assets/images/item5.png" alt="" />
          <span>接单策略</span>
        </div>
        <div class="item">
          <img src="../../assets/images/item6.png" alt="" />
          <span>百问百答</span>
        </div>
        <div class="item">
          <img src="../../assets/images/item7.png" alt="" />
          <span>盈利模式</span>
        </div>
      </div>
    </div>

<!--    <div class="footer">-->
<!--      <div class="first-line">-->
<!--        Copyright 2023 深圳市超元创世科技有限公司 · 联系电话 18948745908 · 地址-->
<!--        广东省深圳市泰然五路9福田天安科技创业园B座8层801 ·-->
<!--        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022144574号</a>-->
<!--      </div>-->
<!--      <div class="second-line">-->
<!--        <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502009647" target="_blank">-->
<!--          <img src="../../assets/images/beian_icon.png" alt=""/>-->
<!--          <p>粤公网安备 44030502009647号</p>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
// pc端
@media only screen and (min-device-width: 768px) {
  #index {
    width: 100%;
    min-width: 1200px;
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 100px;
    box-sizing: border-box;

    .nav {
      width: 1200px;
      height: 100px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        width: 243px;
        height: 45px;
      }
    }

    .title_box {
      width: 1200px;
      height: 138px;
      margin: 100px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .ip_logo {
        width: 125px;
        height: 138px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .lt_logo {
        width: 134px;
        height: 76px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .showings_logo {
        width: 525px;
        height: 79px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .fans_box_logo {
        width: 126px;
        height: 127px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .one_lines {
        margin-left: 78px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-size: 49px;
          font-weight: 600;
          color: #f9f9f9;
        }
      }

      .two_lines {
        margin-left: 78px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 49px;
          font-weight: 600;
          color: #f9f9f9;
        }
      }

      .border_bottom {
        width: 1100px;
        height: 112px;
        position: absolute;
        top: 90px;
        left: 50%;
        transform: translateX(-50%);

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .ip_img {
      width: 866px;
      height: 871px;
      margin: 140px auto 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .lt_img {
      width: 1137px;
      height: 640px;
      margin: 140px auto 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .showings_img {
      width: 1200px;
      margin: 140px auto 0;

      p {
        margin-bottom: 50px;
        text-align: center;
        font-size: 36px;
        font-weight: 600;
        color: #e6e6e6;
      }

      img {
        width: 1200px;
        height: 1095px;
      }
    }

    .showings_img2 {
      width: 1200px;
      height: 572px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .showings_img3 {
      width: 1200px;
      margin: 0 auto;

      .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .lable {
          padding: 8px 20px;
          border: 2px solid;
          box-sizing: border-box;
          background: linear-gradient(
            134deg,
            rgba(238, 238, 238, 0.2) 0%,
            rgba(216, 216, 216, 0.1) 100%
          );
          border-image: linear-gradient(
              311deg,
              rgba(157, 152, 253, 0.2),
              rgba(255, 255, 255, 0.2)
            )
            2 2;
          font-size: 38px;
          font-weight: 400;
          color: #f9f9f9;
        }
      }

      .image {
        width: 1200px;
        height: 529px;
        margin-top: 100px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .text_box {
      width: 100%;
      padding: 50px;
      box-sizing: border-box;
      text-align: center;
      font-weight: 600;
      color: #e6e6e6;
      line-height: 50px;

      p {
        font-size: 23px;
      }

      span {
        font-size: 40px;
      }
    }

    .explain {
      width: 1100px;
      margin: 0 auto;

      .info {
        width: 100%;
        margin-top: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .infoIcon {
          width: 390px;
          height: 454px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .infoTxt {
          width: calc(100% - 554px);

          .topic {
            width: 100%;
            text-align: left;
            font-size: 46px;
            font-weight: 600;
            color: #fd5900;
            line-height: 65px;
          }

          .strip {
            width: 100%;
            text-align: left;
            margin-top: 73px;

            li {
              margin: 8px 0;
              font-size: 26px;
              font-weight: 400;
              color: #e6e6e6;
            }
          }

          .strip li::marker {
            color: #eb5e00;
          }
        }
      }

      .target {
        width: 100%;
        margin-top: 148px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .targetTxt {
          width: calc(100% - 550px);

          .tit {
            width: 100%;
            text-align: left;
            font-size: 46px;
            font-weight: 600;
            color: #fd5900;
          }

          .txt {
            width: 100%;
            margin-top: 20px;

            p {
              font-size: 26px;
              font-weight: 400;
              color: #e6e6e6;
              line-height: 48px;
            }
          }
        }

        .targetIcon {
          width: 383px;
          height: 449px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .bottom {
      width: 100%;
      margin-top: 260px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-size: 36px;
        font-weight: 600;
        color: #f9f9f9;
      }

      .tab {
        width: 698px;
        height: 50px;
        margin-top: 26px;
        border-radius: 12px;
        border: 1px solid #979797;
        text-align: center;
        line-height: 50px;
        font-size: 24px;
        font-weight: 400;
        color: #f9f9f9;
      }

      .items {
        width: 100%;
        margin-top: 96px;
        display: flex;
        justify-content: center;
        align-items: center;

        .item {
          width: 148px;
          height: 157px;
          margin: 0 14px;
          border-radius: 12px;
          border: 1px solid #979797;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            width: 60px;
            height: 60px;
          }

          span {
            margin-top: 8px;
            font-size: 24px;
            font-weight: 400;
            color: #f9f9f9;
          }
        }
      }
    }

    .footer {
      width: 100%;

      .first-line {
        width: 1200px;
        margin: 96px auto 0;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #f9f9f9;

        a {
          font-size: 14px;
          font-weight: 400;
          color: #f9f9f9;
        }

        a:hover {
          color: #fd5900;
        }
      }

      .second-line {
        width: 1200px;
        margin:0 auto;
        padding:20px 0;
        text-align: center;

        a {
          font-size: 14px;
          font-weight: 400;
          color: #f9f9f9;
          display:inline-block;
          vertical-align: middle;

          img {
            float:left;
          }

          p {
            float:left;
            margin: 0 0 0 5px;
            font-size: 14px;
            font-weight: 400;
            color: #f9f9f9;
          }
        }
      }
    }
  }
}

// 移动端
@media only screen and (max-device-width: 768px) {
  #index {
    width: 100%;
    min-width: 375px;
    background: #161616;
    padding-bottom: 60px;
    box-sizing: border-box;

    .nav {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        width: 125px;
        height: 25px;
      }
    }

    .title_box {
      width: 100%;
      height: 42px;
      margin: 30px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .ip_logo {
        width: 37.5px;
        height: 41.4px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .lt_logo {
        width: 35px;
        height: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .showings_logo {
        width: 168.5px;
        height: 25px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .fans_box_logo {
        width: 39px;
        height: 39px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .one_lines {
        margin-left: 23px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-size: 15px;
          font-weight: 600;
          color: #f9f9f9;
        }
      }

      .two_lines {
        margin-left: 23px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 15px;
          font-weight: 600;
          color: #f9f9f9;
        }
      }

      .border_bottom {
        width: 331.2px;
        height: 33.6px;
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .ip_img {
      width: 244px;
      height: 245px;
      margin: 45px auto 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .lt_img {
      width: 95%;
      height: 196px;
      margin: 45px auto 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .showings_img {
      width: 95%;
      margin: 45px auto 0;
      text-align: center;

      p {
        margin-bottom: 20px;
        font-size: 12px;
        font-weight: 600;
        color: #e6e6e6;
      }

      img {
        width: 100%;
        height: 319px;
      }
    }

    .showings_img2 {
      width: 95%;
      height: 164.6px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .showings_img3 {
      width: 95%;
      margin: 0 auto;

      .title {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .lable {
          padding: 5px 20px;
          border: 2px solid;
          box-sizing: border-box;
          background: linear-gradient(
            134deg,
            rgba(238, 238, 238, 0.2) 0%,
            rgba(216, 216, 216, 0.1) 100%
          );
          border-image: linear-gradient(
              311deg,
              rgba(157, 152, 253, 0.2),
              rgba(255, 255, 255, 0.2)
            )
            2 2;
          font-size: 14px;
          font-weight: 400;
          color: #f9f9f9;
        }
      }

      .image {
        width: 100%;
        height: 158.7px;
        margin: 34px auto 0;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .text_box {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      text-align: center;
      font-weight: 600;
      color: #e6e6e6;
      line-height: 45px;

      p {
        font-size: 12px;
      }

      span {
        font-size: 14px;
      }
    }

    .explain {
      width: 85%;
      margin: 0 auto;

      .info {
        width: 100%;
        margin-top: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .infoIcon {
          width: 117px;
          height: 136px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .infoTxt {
          width: calc(100% - 150px);

          .topic {
            width: 100%;
            text-align: left;
            font-size: 14px;
            font-weight: 600;
            color: #fd5900;
          }

          .strip {
            width: 100%;
            text-align: left;
            margin-top: 18px;

            li {
              margin: 4px 0 0 15px;
              font-size: 12px;
              font-weight: 400;
              color: #e6e6e6;
            }
          }

          .strip li::marker {
            color: #eb5e00;
          }
        }
      }

      .target {
        width: 100%;
        margin-top: 148px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .targetTxt {
          width: calc(100% - 550px);

          .tit {
            width: 100%;
            text-align: left;
            font-size: 46px;
            font-weight: 600;
            color: #fd5900;
          }

          .txt {
            width: 100%;
            margin-top: 20px;

            p {
              font-size: 26px;
              font-weight: 400;
              color: #e6e6e6;
              line-height: 48px;
            }
          }
        }

        .targetIcon {
          width: 383px;
          height: 449px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .bottom {
      width: 100%;
      margin-top: 77px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-size: 14px;
        font-weight: 600;
        color: #f9f9f9;
      }

      .tab {
        width: 260px;
        height: 25px;
        margin-top: 8px;
        border-radius: 4px;
        border: 1px solid #979797;
        text-align: center;
        line-height: 25px;
        font-size: 12px;
        font-weight: 400;
        color: #f9f9f9;
      }

      .items {
        width: 360px;
        margin: 30px auto 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        .item {
          width: 75px;
          height: 80px;
          margin: 0 7.5px 15px;
          border-radius: 12px;
          border: 1px solid #979797;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            width: 33px;
            height: 33px;
          }

          span {
            width: 100%;
            height: 20px;
            text-align: center;
            line-height: 20px;
            font-size: 12px;
            font-weight: 400;
            color: #f9f9f9;
          }
        }

        :nth-child(1) {
          width: 118px;
          height: 84px;
        }

        :nth-child(2) {
          width: 79px;
          height: 84px;
        }

        :nth-child(3) {
          width: 118px;
          height: 84px;
        }
      }
    }

    .footer {
      width: 100%;

      .first-line {
        width: 100%;
        margin: 30px auto 0;
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        color: #f9f9f9;
        line-height: 20px;

        a {
          font-size: 10px;
          font-weight: 400;
          color: #f9f9f9;
        }

        a:hover {
          color: #fd5900;
        }
      }

      .second-line {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        line-height: 20px;

        a {
          font-size: 10px;
          font-weight: 400;
          color: #f9f9f9;
          display:inline-block;
          vertical-align: middle;

          img {
            float:left;
          }

          p {
            float:left;
            margin: 0 0 0 5px;
            font-size: 10px;
            font-weight: 400;
            color: #f9f9f9;
          }
        }
      }
    }
  }
}
</style>
